@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
ul {
    margin: 0;
    padding: 0
}

ul li {
    list-style: none
}

body {
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color: #a0a6ad;
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    overflow-x: hidden
}

html {
    overflow-x: hidden
}

row {
    margin: 0px;
}

p {
    font-size: 14px;
    color: #a0a6ad;
    line-height: 26px;
    font-family: roboto, sans-serif;
    margin-bottom: 0
}

a:hover,
a:focus {
    color: #3c9cfd
}

a {
    color: #3c9cfd;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

h1,
h2,
h3,
h4,
h5 {
    font-family: poppins, sans-serif;
    font-size: 40px;
    font-weight: 700;
    color: #191c1e
}

ul {
    margin: 0;
    padding: 0
}

ul li {
    list-style: none
}

a:hover,
a:focus {
    text-decoration: none;
    outline: none
}

a:not([href]):not([tabindex]) {
    color: #fff
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: #fff
}

.bg-transparent {
    background: 0 0!important
}

.bg-defult {
    background: #3c9cfd!important
}

.section {
    padding: 80px 0 65px
}

.section-header {
    color: #fff;
    margin-bottom: 50px;
    text-align: center;
    position: relative
}

.section-header .section-title {
    font-size: 30px;
    margin-bottom: 20px;
    font-family: poppins, sans-serif;
    font-weight: 600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-color: #2dfbff;
    background-image: linear-gradient(to right, #3c96ff, #2dfbff);
    position: relative
}

.btn {
    font-size: 14px;
    padding: 10px 30px;
    border-radius: 30px;
    font-family: poppins, sans-serif;
    letter-spacing: 1px;
    font-weight: 600;
    color: #fff;
    border: none;
    text-transform: uppercase;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    display: inline-block
}

.btn-subtitle {
    color: #191c1e;
    font-family: poppins, sans-serif;
    background: #dbecff;
    text-align: center;
    border-radius: 30px;
    padding: 6px 30px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 14px
}

.btn:focus,
.btn:active {
    box-shadow: none;
    outline: none
}

.btn-common {
    background-image: linear-gradient(to right, #3c96ff, #2dfbff);
    position: relative;
    z-index: 1
}

.btn-common:hover {
    color: #fff;
    box-shadow: 0 8px 9px 0 rgba(96, 94, 94, .17)
}

.btn-effect {
    overflow: hidden
}

.btn-effect:after {
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .1);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.btn-effect:hover:after {
    width: 100%
}

.btn-border {
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    background-color: transparent;
    border-color: #3c9cfd;
    color: #3c9cfd
}

.btn-border:hover {
    box-shadow: 0 8px 9px 0 rgba(96, 94, 94, .17)
}

.btn-border-filled {
    cursor: pointer;
    background-color: #fff;
    border: 2px solid #fff;
    color: #3c9cfd
}

.btn-border-filled:hover {
    color: #3c9cfd;
    background-color: #fff;
    box-shadow: 0 8px 9px 0 rgba(96, 94, 94, .17)
}

.btn-lg {
    padding: 14px 33px;
    text-transform: uppercase;
    font-size: 16px
}

.btn-rm {
    color: #3c9cfd;
    padding: 10px 0;
    text-transform: capitalize;
    font-size: 16px
}

.btn-rm i {
    vertical-align: middle
}

.btn-trial {
    background: #fff;
    color: #191c1e;
    padding: 5px 20px;
    margin-left: 30px
}

.btn-trial:hover,
.btn-trial:active,
.btn-trial:focus {
    color: #3c9cfd
}

.btn-apple {
    border-width: 2px;
    border-color: #3c96ff;
    border-style: solid;
    background: linear-gradient(to right, #3c96ff 0%, #2dfbff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out
}

.btn-apple i {
    background: linear-gradient(to right, #3c96ff 0%, #2dfbff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.btn-apple:hover,
.btn-apple:active,
.btn-apple:focus {
    background: linear-gradient(to right, #3c96ff 0%, #2dfbff 100%)!important;
    -webkit-background-clip: text!important;
    -webkit-text-fill-color: transparent!important
}

button:focus {
    outline: none!important
}

.btn-border-fill {
    background: #fff;
    border-color: #fff;
    color: #3c9cfd
}

.clear {
    clear: both
}

.mt {
    margin-top: 80px
}

.mt-30 {
    margin-top: 30px
}

.mb-30 {
    margin-bottom: 30px
}

.mb-50 {
    margin-bottom: 50px
}

.padding-0 {
    padding: 0
}

.modal-content {
    width: 600px;
    height: 400px;
    margin-top: 100px;
}

iframe {
    width: 600px;
    height: 400px;
}

@media(min-width:768px) and (max-width:1024px) {
    .modal-content {
        width: 100%;
        height: 300px;
        margin-top: 100px;
    }
    iframe {
        width: 100%;
        height: 300px;
    }
}

@media only screen and (max-width:767px) {
    .modal-content {
        width: 100%;
        height: 300px;
        margin-top: 100px;
    }
    iframe {
        width: 100%;
        height: 300px;
    }
}

@media(min-width:320px) and (max-width:480px) {
    .modal-content {
        width: 100%;
        height: 300px;
        margin-top: 100px;
    }
    iframe {
        width: 100%;
        height: 300px;
    }
}


/* Importing Bootstrap SCSS file. */

@import '~bootstrap/scss/bootstrap';